.Certificates-section{
    width: 100%;
}

.Certificates-section .Certificates-container{
    max-width: 1920px;
    margin:  0 auto;
    padding: 30px 60px;

    display: flex;
    flex-direction: column;
    gap: 30px;
}

.Certificates-section .Certificates-container .heading{
    width: 100%;
    text-align: center;
}

.Certificates-section .Certificates-container .heading span{
    font-size: 30px;
    font-weight: 700;
}

.Certificates-section .Certificates-container .main-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Certificates-section .Certificates-container .main-container .col{
    width: 21%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    box-shadow: 0px 0px 10px rgb(178, 178, 178);
    border-radius: 9px;
    padding: 5px 8px;
}

.Certificates-section .Certificates-container .main-container .col .img{
    width: 100%;
    height: 200px;
}

.Certificates-section .Certificates-container .main-container .col .img img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.Certificates-section .Certificates-container .main-container .col .pdf-name{
    width: 100%;
    text-align: center;
}

.Certificates-section .Certificates-container .main-container .col .pdf-name h3{
    font-weight: 600;
}

@media (max-width:880px) {

    
.Certificates-section .Certificates-container .main-container{
    flex-wrap: wrap;
    gap: 20px;
}

.Certificates-section .Certificates-container .main-container .col{
    width: 48%;
}

    
}

@media (max-width:650px){
    .Certificates-section .Certificates-container{
        padding: 40px 20px;
    }
}

@media (max-width:540px) {

    .Certificates-section .Certificates-container .main-container .col{
        width: 100%;
    }
    
}
.first-img:hover>.second-img {
    opacity: 1;
}

.first-img {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(.17, .67, .83, .67);
}

.first-img img {
    transition: all 0.4s cubic-bezier(.17, .67, .83, .67);

}

.second-img {
    transition: all 0.4s cubic-bezier(.17, .67, .83, .67);

}

.buttons {
    padding-right: 0.3rem;
    position: absolute;
    top: 5%;
    transition: right 0.2s ease-in-out;
    right: -100%;
}

.buttons ul {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
    text-align: center;
}

.buttons ul li {
    width: 32px;
    display: flex;
    align-items: center;
    height: 32px;
    justify-content: center;
    text-align: center;
    border-radius: 44px;
    background-color: white;
}

.first-img:hover>.buttons {
    right: 0;
}

.photo .glow-wrap{
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  margin-top: -10px;
}

.photo .glow{
  display: block;
  position:absolute;
  width: 40%;
  height: 200%;
  background: rgba(255,255,255,.2);
  top: 0;
  filter: blur(5px);
  transform: rotate(45deg) translate(-450%, 0);
  transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.photo:hover .glow{
  transform: rotate(45deg) translate(450%, 0);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.tag{
    position: absolute;
    top: 0;
    background-color: red;
    margin-top: 12px;
    color: white;
    font-size: 11px;
    padding: 0.2rem 1rem;
    border-radius: 44px;
    margin-left: 12px;
}
/* .hdvh{
    height: 80dvh;
} */
::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar {
    display: none;
}

.bg {
    width: 100%;
    height: 80dvh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-image: url('https://marketplace.canva.com/EAFEH3mIUaM/1/0/1600w/canva-dark-grey-and-white-minimalist-new-fashion-collection-banner-nvaqxg-8iXI.jpg')
}

.bgs {
    width: 100%;
    height: 80dvh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-image: url('https://www.jkspices.com/wp-content/uploads/2020/07/banner_1.jpg')
}

.btn-views {
    margin-top: 22px;
    background-color: white;
    padding: 0.9rem 4rem;
    border-radius: 44px;
    font-weight: 700;
    border: none;
    white-space: nowrap;
    outline: none;
    color: #16153C;
    transition: background 0.2s ease-in-out;
}

.btn-views:hover {
    background: transparent;
    border: 1px solid white;
    color: white;
}